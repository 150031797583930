<template>
  <div style="text-align: center;">
    <div style="margin-top: 80px; font-size: 24px; font-weight: 600;">
      OA 单点登录中
    </div>
    <a-spin size="large" :spinning="spinning" style="margin-top: 80px;"></a-spin>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '@/config'

export default {
  name: 'SSOPage',
  data () {
    return {
      spinning: false
    }
  },
  computed: {
    ...mapGetters(['routes'])
  },
  mounted () {
    this.handleSSOToken()
  },
  methods: {
    ...mapActions(['LoginSSO']),
    handleSSOToken () {
      this.spinning = true
      this.LoginSSO({ token: this.$route.query.token }).then(res => {
        this.$notification.success({
          message: res.message,
          description: `欢迎使用 ${config.title}`
        })
        if (res.data.password_expires_in && res.data.password_expires_in <= 7) {
          this.$nextTick().then(() => {
            this.$notification.warn({
              message: '密码到期提醒',
              description: `您的密码将于 ${res.data.password_expires_in} 天后过期, 请及时修改密码`
            })
          })
        }
        this.$router.push({ name: 'Index' })
      }).catch(() => {
        this.$router.replace({ name: 'Login' })
      })
        .finally(() => {
          this.spinning = false
        })
    }
  }
}
</script>
